var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iq-top-navbar"},[_c('div',{},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light pt-0 pb-0",staticStyle:{"line-height":"60px"}},[_c('a',{staticClass:"navbar-brand",attrs:{"href":_vm.VUE_APP_SERVER_URL}},[_c('div',{staticClass:"iq-navbar-logo d-flex justify-content-between"},[_c('img',{staticClass:"img-fluid navBarLogo",attrs:{"src":_vm.logo,"alt":"logo"}})])]),_c('div',{staticClass:"navbar-toggler",staticStyle:{"background-color":"transparent"},attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.MenuShowHide}},[_vm._m(0)]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto navbar-list"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle pointer",attrs:{"id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Events ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.openUrl('/public/GideEvents')}}},[_vm._v("Upcoming Events")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.openUrl('/public/GideEvents?past_event=true')}}},[_vm._v("Previous Events")])])]),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link pointer"},[(_vm.userData && _vm.userData.user_name)?_c('span',{on:{"click":function($event){return _vm.signOut()}}},[_vm._v(" "+_vm._s(_vm.userData.user_name)+" (Sign out) ")]):_c('span',{on:{"click":function($event){return _vm.openUrl('/auth/login')}}},[_vm._v(" Sign In/Sign Up ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-menu"},[_c('div',{staticClass:"main-circle"},[_c('i',{staticClass:"ri-menu-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item active"},[_c('a',{attrs:{"href":"https://www.gide.ai"}},[_vm._v(" Home ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"https://www.gide.ai/about"}},[_vm._v(" About ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"https://www.gide.ai/activities"}},[_vm._v(" Activities ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"https://www.gide.ai/blog"}},[_vm._v(" Blogs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"https://www.gide.ai/gallery"}},[_vm._v(" Gallery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{attrs:{"href":"https://www.gide.ai/contact-us"}},[_vm._v(" Contact Us ")])])
}]

export { render, staticRenderFns }